import { useMemo } from 'react'
import { forEach, isEmpty, size } from 'lodash'
import {
  Card,
  Chip,
  Grid,
  Heading,
  Table,
} from '@enterprise-ui/canvas-ui-react'
import { startCase } from '../../../../common/util/stringUtil'
import { getAlertStatusColor } from '../../util/alertStatusUtil'

const shipmentColumnDefs = [
  {
    headerName: 'Shipment #',
    field: 'shipment_id',
    isRowHeader: true,
  },
  {
    headerName: 'PO #',
    field: 'po_id',
  },
  {
    headerName: 'Bill of Lading #',
    field: 'bill_of_lading',
  },
  {
    headerName: 'Commodity',
    field: 'commodity',
  },
]

const placeholderText = (
  <p className="hc-fs-sm">
    <strong>N/A</strong>
  </p>
)

const mapLoadBookings = (load) => {
  const mappedLoadBookings = []

  forEach(load.bookings, (booking, key) => {
    mappedLoadBookings.push({
      shipment_id: booking.booking_id,
      po_id: booking.purchase_order,
      bill_of_lading: booking.bill_of_lading,
      commodity: startCase(booking.commodity.commodity_code),
    })
  })

  return mappedLoadBookings
}
const LoadInfoCard = ({ load, moveData, moveError }) => {
  const shipmentRowData = useMemo(() => mapLoadBookings(load), [load])

  const formatMoveIds = (moveData) => {
    if (!moveData) return placeholderText

    const moveIds = moveData?.reduce(function (ids, move) {
      if (move.move_id) {
        const formatted = <li>{move.move_id}</li>
        ids.push(formatted)
      }
      return ids
    }, [])

    return isEmpty(moveIds) ? placeholderText : moveIds
  }

  const formatScheduleIds = (moveData) => {
    if (!moveData) return placeholderText

    const scheduleIds = moveData.reduce(function (ids, move) {
      if (move.schedule_id) {
        const formatted = <li>{move.schedule_id}</li>
        ids.push(formatted)
      }
      return ids
    }, [])

    return isEmpty(scheduleIds) ? placeholderText : scheduleIds
  }

  return (
    <Grid.Item>
      <Card className="hc-ph-expanded hc-mb-expanded">
        <Grid.Container
          justify="space-between"
          align="center"
          className="hc-pb-normal hc-pt-min"
        >
          <Grid.Item>
            <Heading size={4}>{`Details for ${load.load_id}`}</Heading>
          </Grid.Item>
          <Grid.Item aria-label={`Alert Status for Load ${load.load_id}`}>
            {load.alert_status && (
              <Chip size="dense" color={getAlertStatusColor(load.alert_status)}>
                {load.alert_status}
              </Chip>
            )}
          </Grid.Item>
        </Grid.Container>
        <Grid.Container direction="row" justify="space-between">
          <Grid.Item>
            <Grid.Container direction="column">
              <Grid.Item>
                <Grid.Container direction="column" spacing="none">
                  <Grid.Item>
                    <p className="hc-fs-xs">Load ID</p>
                  </Grid.Item>
                  <Grid.Item>
                    <p className="hc-fs-sm">
                      <strong>{load.load_id}</strong>
                    </p>
                  </Grid.Item>
                </Grid.Container>
              </Grid.Item>
              <Grid.Item>
                <Grid.Container direction="column" spacing="none">
                  <Grid.Item>
                    <p className="hc-fs-xs">Trip ID</p>
                  </Grid.Item>
                  <Grid.Item>
                    {load.trip_id ? (
                      <p className="hc-fs-sm">
                        <strong>{load.trip_id}</strong>
                      </p>
                    ) : (
                      placeholderText
                    )}
                  </Grid.Item>
                </Grid.Container>
              </Grid.Item>
              <Grid.Item>
                <Grid.Container direction="column" spacing="none">
                  <Grid.Item>
                    <p className="hc-fs-xs">Route ID</p>
                  </Grid.Item>
                  <Grid.Item>
                    {load.route_id ? (
                      <p className="hc-fs-sm">
                        <strong>{load.route_id}</strong>
                      </p>
                    ) : (
                      placeholderText
                    )}
                  </Grid.Item>
                </Grid.Container>
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>
          <Grid.Item>
            <Grid.Container direction="column">
              <Grid.Item>
                <Grid.Container direction="column" spacing="none">
                  <Grid.Item>
                    <p className="hc-fs-xs">Trailer Number</p>
                  </Grid.Item>
                  <Grid.Item>
                    {load.trailer_number ? (
                      <p className="hc-fs-sm">
                        <strong>{load.trailer_number}</strong>
                      </p>
                    ) : (
                      placeholderText
                    )}
                  </Grid.Item>
                </Grid.Container>
              </Grid.Item>
              <Grid.Item>
                <Grid.Container direction="column" spacing="none">
                  {/*TODO: FMDT-4080 - move id and schedule id*/}
                  <Grid.Item>
                    <p className="hc-fs-xs">Move ID</p>
                  </Grid.Item>
                  {
                    <Grid.Item>
                      {(moveError && (
                        <Heading className="hc-clr-error" size={6}>
                          Error getting Move ID
                        </Heading>
                      )) || (
                        <p className="hc-fs-sm">
                          <strong>{formatMoveIds(moveData)}</strong>
                        </p>
                      )}
                    </Grid.Item>
                  }
                </Grid.Container>
              </Grid.Item>
              <Grid.Item>
                <Grid.Container direction="column" spacing="none">
                  <Grid.Item>
                    <p className="hc-fs-xs">Schedule ID</p>
                  </Grid.Item>
                  <Grid.Item>
                    {(moveError && (
                      <Heading className="hc-clr-error" size={6}>
                        Error getting Schedule ID
                      </Heading>
                    )) || (
                      <p className="hc-fs-sm">
                        <strong>{formatScheduleIds(moveData)}</strong>
                      </p>
                    )}
                  </Grid.Item>
                </Grid.Container>
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>
          <Grid.Item>
            <Grid.Container direction="column">
              <Grid.Item>
                <Grid.Container direction="column" spacing="none">
                  <Grid.Item>
                    <p className="hc-fs-xs">SCAC</p>
                  </Grid.Item>
                  <Grid.Item>
                    <p className="hc-fs-sm">
                      {load.carrier && load.carrier.scac ? (
                        <p className="hc-fs-sm">
                          <strong>{load.carrier.scac}</strong>
                        </p>
                      ) : (
                        placeholderText
                      )}
                    </p>
                  </Grid.Item>
                </Grid.Container>
              </Grid.Item>
              <Grid.Item>
                <Grid.Container direction="column" spacing="none">
                  <Grid.Item>
                    <p className="hc-fs-xs">Origin</p>
                  </Grid.Item>
                  <Grid.Item>
                    <p className="hc-fs-sm">
                      <strong>{load.origin.name}</strong>
                    </p>
                  </Grid.Item>
                </Grid.Container>
              </Grid.Item>
              <Grid.Item>
                <Grid.Container direction="column" spacing="none">
                  <Grid.Item>
                    <p className="hc-fs-xs">Destination</p>
                  </Grid.Item>
                  <Grid.Item>
                    <p className="hc-fs-sm">
                      <strong>{load.destination.name}</strong>
                    </p>
                  </Grid.Item>
                </Grid.Container>
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>
        </Grid.Container>
        <Grid.Container className="hc-pv-normal" direction="column">
          <Grid.Item>
            <Heading size={5}>Shipments</Heading>
          </Grid.Item>
          <Grid.Item className="shipment-table">
            {(size(load.bookings) && (
              <Table
                name="shipments-table"
                aria-label="Shipments Table"
                data-testid="Shipments-Table"
                data={{
                  columnDefs: shipmentColumnDefs,
                  rowData: shipmentRowData,
                }}
              />
            )) || (
              <Heading size={6}>
                Shipment data is not available for this load.
              </Heading>
            )}
          </Grid.Item>
        </Grid.Container>
      </Card>
    </Grid.Item>
  )
}
export default LoadInfoCard
