import { formatDateTimeForInput } from '../../../common/util/dateUtil'

export const DEFAULT_DASHBOARD_SEARCH_FILTERS = {
  scacs: [],
  origin_ids: [],
  critical_departure_start_time: '',
  critical_departure_end_time: '',
  arrival_start_time: '',
  arrival_end_time: '',
  load_statuses: [],
}

export const DEFAULT_MM_DASHBOARD_SEARCH_FILTERS = () => {
  const defaultStartTime = formatDateTimeForInput(
    new Date(new Date().setDate(new Date().getDate() - 1)).setHours(8, 0, 0, 0),
  )
  const defaultEndTime = formatDateTimeForInput(
    new Date().setHours(7, 59, 0, 0),
  )
  return {
    ...DEFAULT_DASHBOARD_SEARCH_FILTERS,
    arrival_start_time: defaultStartTime,
    arrival_end_time: defaultEndTime,
    selected_filters: ['arrival_start_time', 'arrival_end_time'],
  }
}
