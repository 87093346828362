import { useState, useEffect } from 'react'
import { upperCase } from 'lodash'
import { featureFlag } from '@dlm/common'
import {
  Button,
  Chip,
  Grid,
  Heading,
  Drawer,
  Tabs,
} from '@enterprise-ui/canvas-ui-react'

import { LOAD_STATUS } from '../constants/loadConstants'
import LoadInfoCard from './cards/LoadInfoCard'
import StopsInfoCard from './cards/StopsInfoCard'
import LifecycleEventsCard from './cards/LifecycleEventsCard'
import trackingService from '../services/trackingService'
import { convertToLocationZone } from '../../../common/util/dateUtil'

const LoadDetails = ({ isOpen, closeModal, load }) => {
  const [stopEventData, setStopEventData] = useState(null)
  const [moveData, setMoveData] = useState(null)
  const [stopEventError, setStopEventError] = useState(false)
  const [moveError, setMoveError] = useState(false)

  const getStopEvents = () => {
    trackingService
      .getStopEvents(load.load_id)
      .then((resp) => {
        setStopEventData(resp[0]?.stops || null)
      })
      .catch((error) => {
        console.error(error)
        setStopEventError(true)
      })
  }

  const getMoveData = () => {
    trackingService
      .getMoves(load.load_id)
      .then((resp) => {
        setMoveData(resp)
      })
      .catch((error) => {
        console.error(error)
        setMoveError(true)
      })
  }

  const getDriverStartedTs = (load) => {
    const event = load?.lifecycle_events?.find(
      (event) => event.event_code === 'DS',
    )
    return event?.event_timestamp && event?.time_zone_id
      ? convertToLocationZone(event.event_timestamp, event.time_zone_id)
      : undefined
  }

  const driverStartedTs = getDriverStartedTs(load)

  const [selectedTab, setSelectedTab] = useState('stops')

  function getLoadStatusColor(loadStatus) {
    let status
    switch (loadStatus) {
      case LOAD_STATUS.AUCTION:
      case LOAD_STATUS.COMPLETED:
      case LOAD_STATUS.IN_TRANSIT:
      case LOAD_STATUS.TENDER_ACCEPTED:
      case LOAD_STATUS.TENDERED:
        status = 'success'
        break
      case LOAD_STATUS.TENDER_EXPIRED:
      case LOAD_STATUS.TENDER_REJECTED:
        status = 'error'
        break
      case LOAD_STATUS.CANCELED:
      case LOAD_STATUS.TENDER_CANCELED:
        status = 'default'
        break
      default:
        status = 'default'
    }
    return status
  }

  useEffect(() => {
    if (isOpen) {
      getStopEvents()
      getMoveData()
    }
    // eslint-disable-next-line
  }, [isOpen])

  return (
    <Drawer
      hasOverlay={false}
      isVisible={isOpen}
      onRequestClose={closeModal}
      headingText={
        <Button className="hc-ma-dense" type="primary" onClick={closeModal}>
          Return to Load Tracking
        </Button>
      }
      xs={12}
      style={{
        height: 'calc(100vh - 56px)',
        top: '56px',
        overflow: 'scroll',
      }}
    >
      <Grid.Container
        className="load-details-container"
        justify="space-between"
        align="center"
      >
        <Grid.Item>
          <Grid.Container justify="flex-start" align="center">
            <Grid.Item xs>
              <Heading size={3}>Load {load?.load_id} </Heading>
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
        <Grid.Item>
          <Grid.Container justify="flex-end" align="center" spacing="expanded">
            {/*TODO: update when we have audit data*/}
            {/*<Grid.Item>*/}
            {/*  <p>Updated by FirstName.LastName, 00-00-0000, 00:00</p>*/}
            {/*</Grid.Item>*/}
            {driverStartedTs && (
              <Grid.Item>
                <Grid.Container direction="column" spacing="dense">
                  Driver Started
                  <strong>{driverStartedTs}</strong>
                </Grid.Container>
              </Grid.Item>
            )}
            <Grid.Item>
              <Chip
                aria-label={`Load Status Chip for Load ${load?.load_id ?? '***'}`}
                size="expanded"
                color={getLoadStatusColor(load?.load_status)}
              >
                {upperCase(load?.load_status ?? 'load status')}
              </Chip>
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
      </Grid.Container>
      <Grid.Container className="load-details-container" direction="column">
        <Grid.Item>
          <Grid.Item m={12}>
            <Tabs
              activeTab={selectedTab}
              onTabSelect={(event, tab) => {
                setSelectedTab(tab.name)
              }}
              disableAdaptive
            >
              <Tabs.Item name="stops">Stops</Tabs.Item>
              {featureFlag(
                ['prod'],
                <Tabs.Item name="lifecycle_events">Lifecycle Events</Tabs.Item>,
              )}
              <Tabs.Item name="load_details">Details</Tabs.Item>
            </Tabs>
          </Grid.Item>
        </Grid.Item>
        {load && (
          <>
            {selectedTab === 'stops' && (
              <StopsInfoCard
                load={{ ...load }}
                stopEventData={stopEventData}
                moveData={moveData}
                stopEventError={stopEventError}
                moveError={moveError}
              />
            )}
          </>
        )}
        {featureFlag(
          ['prod'],
          <>
            {load && (
              <>
                {selectedTab === 'lifecycle_events' && <LifecycleEventsCard />}
              </>
            )}
          </>,
        )}
        {load && (
          <>
            {selectedTab === 'load_details' && (
              <LoadInfoCard
                load={{ ...load }}
                moveData={moveData}
                moveError={moveError}
              />
            )}
          </>
        )}
      </Grid.Container>
    </Drawer>
  )
}

export default LoadDetails
