import { startCase } from '../../../../common/util/stringUtil'
import { getAlertStatusColor } from '../../util/alertStatusUtil'
import { filter, forEach } from 'lodash'
import {
  Card,
  Chip,
  ExpandableSection,
  Grid,
  Heading,
  Table,
} from '@enterprise-ui/canvas-ui-react'
import {
  convertToLocationZone,
  formatDate,
} from '../../../../common/util/dateUtil'

const stopEventsColumnDefs = [
  {
    headerName: 'Event Code',
    field: 'event_code',
    width: 115,
    isRowHeader: true,
  },
  {
    headerName: 'Event Description',
    field: 'event_desc',
  },
  {
    headerName: 'Event Timestamp',
    field: 'event_ts',
    width: 200,
    sortable: true,
  },
  {
    headerName: 'Reason Code',
    field: 'reason_code',
    width: 125,
  },
  {
    headerName: 'Reason Description',
    field: 'reason_desc',
    width: 165,
  },
  {
    headerName: 'Received Timestamp',
    field: 'received_ts',
    width: 200,
    sortable: true,
    defaultSort: 'asc',
    sortType: 'date',
  },
  {
    headerName: 'Tracking Number',
    field: 'tracking_number',
    width: 135,
  },
]

const mapStopEvents = (stopData, sequence_number) => {
  const mappedStopEvents = []
  const sequenceNumber = sequence_number.toString()
  forEach(stopData, (stop) => {
    forEach(stop.events, (edi_event) => {
      mappedStopEvents.push({
        event_code: edi_event.event_code,
        event_desc: startCase(edi_event.event_code_description),
        event_ts: convertToLocationZone(
          edi_event.event_timestamp,
          edi_event.event_time_zone_id,
        ),
        reason_code: edi_event.event_reason_code,
        reason_desc: startCase(edi_event.event_reason_code_description),
        received_ts: formatDate(edi_event.received_date),
        tracking_number: edi_event.event_tracking_number,
        stop_num: stop.stop_sequence_number.toString(),
      })
    })
  })

  return filter(mappedStopEvents, ['stop_num', sequenceNumber])
}
const placeholderText = (
  <p className="hc-fs-sm">
    <strong>N/A</strong>
  </p>
)
const StopsInfoCard = ({
  load,
  stopEventData,
  moveData,
  stopEventError,
  moveError,
}) => {
  const stopEventsRowData = (sequence_number) =>
    mapStopEvents(stopEventData, sequence_number)

  const getLatestEventStatus = (sequence_number) => {
    let mappedLatestEventStatus = []

    forEach(stopEventData, (stop) => {
      if (stop.stop_sequence_number === sequence_number && stop.latest_event) {
        mappedLatestEventStatus = stop.latest_event.event_code_description
      }
    })

    return startCase(mappedLatestEventStatus)
  }

  const checkEdiEvents = (sequence_number) => {
    let ediEvents = false
    const sequenceNumber = sequence_number

    forEach(stopEventData, (stop) => {
      if (
        stop.stop_sequence_number === sequenceNumber &&
        stop.events.length > 0
      ) {
        ediEvents = true
      }
    })

    return ediEvents
  }

  return load.stops.map((stop, index) => {
    const originMoveData = moveData
      ? moveData.find((data) => data.origin === stop.location_code)
      : null
    const destinationMoveData = moveData
      ? moveData.find((data) => data.destination === stop.location_code)
      : null
    const dynamicEta = destinationMoveData
      ? destinationMoveData.dynamic_eta_timestamp
      : null
    const geofenceArrivalAtStore = destinationMoveData
      ? destinationMoveData.geofence_arrival_timestamp
      : null
    const trailerReleaseTimestamp = originMoveData
      ? originMoveData.trailer_release_timestamp
      : null
    const trailerCloseTimestamp = originMoveData
      ? originMoveData.trailer_close_timestamp
      : null

    return (
      <Grid.Item className="hc-pt-none hc-pb-expanded">
        <Card>
          <ExpandableSection startExpanded toggleLocation="left">
            <Grid.Container justify="space-between" align="center">
              <Grid.Item className="hc-pl-expanded hc-pt-none">
                <Grid.Container
                  direction="row"
                  align="center"
                  justify="flex-start"
                >
                  <Grid.Item>
                    <Grid.Container spacing="dense">
                      <Grid.Item>
                        <Heading size={4}>Stop {stop.sequence_number}</Heading>
                      </Grid.Item>
                    </Grid.Container>
                  </Grid.Item>
                  <Grid.Item>
                    <Grid.Container direction="column" spacing="none">
                      <Grid.Item>
                        <Heading size={5}>
                          {stop.location_name &&
                            `${startCase(stop.location_name)}, ${
                              stop.location_code
                            } - ${stop.type}`}
                        </Heading>
                      </Grid.Item>
                      <Grid.Item>
                        {stop.address &&
                          `${startCase(
                            stop.address.street,
                          )}, ${startCase(stop.address.city)}, ${
                            stop.address.state_code
                          } ${stop.address.postal_code}`}
                      </Grid.Item>
                    </Grid.Container>
                  </Grid.Item>
                </Grid.Container>
              </Grid.Item>
              <Grid.Item>
                <Grid.Container align="center">
                  {checkEdiEvents(stop.sequence_number) &&
                    getLatestEventStatus(stop.sequence_number) && (
                      <Grid.Item>
                        <Chip
                          aria-label={`Latest Event Chip for Stop ${stop.sequence_number}`}
                          size="dense"
                        >
                          {getLatestEventStatus(stop.sequence_number)}
                        </Chip>
                      </Grid.Item>
                    )}
                  {stop.alert_status && (
                    <Grid.Item
                      aria-label={`Alert Status for Stop ${stop.sequence_number}`}
                      className="hc-pl-none"
                    >
                      <Chip
                        size="dense"
                        color={getAlertStatusColor(stop.alert_status)}
                      >
                        {stop.alert_status}
                      </Chip>
                    </Grid.Item>
                  )}
                </Grid.Container>
              </Grid.Item>
            </Grid.Container>
            <ExpandableSection.Content>
              <Grid.Container
                className="hc-pa-normal"
                direction="row"
                justify="space-between"
              >
                <Grid.Item>
                  <Grid.Container direction="column" spacing="none">
                    <Grid.Item>
                      {(stop.type === 'PICK' && (
                        <p className="hc-fs-xs">Expected Pickup Date</p>
                      )) || <p className="hc-fs-xs">Expected Drop Date</p>}
                    </Grid.Item>
                    <Grid.Item>
                      {stop.planned_arrival_time ? (
                        <p className="hc-fs-sm">
                          <strong>
                            {convertToLocationZone(
                              stop.planned_arrival_time,
                              stop.time_zone_id,
                            )}
                          </strong>
                        </p>
                      ) : (
                        placeholderText
                      )}
                    </Grid.Item>
                  </Grid.Container>
                </Grid.Item>
                {trailerCloseTimestamp && (
                  <Grid.Item>
                    <Grid.Container direction="column" spacing="none">
                      <Grid.Item>
                        <p className="hc-fs-xs">Trailer Close</p>
                      </Grid.Item>
                      <Grid.Item>
                        <p className="hc-fs-sm">
                          <strong>
                            {convertToLocationZone(
                              trailerCloseTimestamp,
                              stop.time_zone_id,
                            )}
                          </strong>
                        </p>
                      </Grid.Item>
                    </Grid.Container>
                  </Grid.Item>
                )}
                {trailerReleaseTimestamp && (
                  <Grid.Item>
                    <Grid.Container direction="column" spacing="none">
                      <Grid.Item>
                        <p className="hc-fs-xs">Trailer Release</p>
                      </Grid.Item>
                      <Grid.Item>
                        <p className="hc-fs-sm">
                          <strong>
                            {convertToLocationZone(
                              trailerReleaseTimestamp,
                              stop.time_zone_id,
                            )}
                          </strong>
                        </p>
                      </Grid.Item>
                    </Grid.Container>
                  </Grid.Item>
                )}
                {geofenceArrivalAtStore && (
                  <>
                    <Grid.Item>
                      <Grid.Container direction="column" spacing="none">
                        <Grid.Item>
                          <p className="hc-fs-xs">Geofence Arrival At Store</p>
                        </Grid.Item>
                        <Grid.Item>
                          <p className="hc-fs-sm">
                            <strong>
                              {convertToLocationZone(
                                geofenceArrivalAtStore,
                                stop.event_time_zone_id,
                              )}
                            </strong>
                          </p>
                        </Grid.Item>
                      </Grid.Container>
                    </Grid.Item>
                    <Grid.Item className="hc-mr-4x"></Grid.Item>
                  </>
                )}
                {dynamicEta && (
                  <>
                    <Grid.Item>
                      <Grid.Container direction="column" spacing={{ xs: 1 }}>
                        <Grid.Item>
                          <p className="hc-fs-xs">Dynamic ETA</p>
                        </Grid.Item>
                        <Grid.Item>
                          {
                            <p className="hc-fs-sm">
                              <strong>
                                {convertToLocationZone(
                                  dynamicEta,
                                  stop.event_time_zone_id,
                                )}
                              </strong>
                            </p>
                          }
                        </Grid.Item>
                      </Grid.Container>
                    </Grid.Item>
                    <Grid.Item className="hc-mr-4x"></Grid.Item>
                  </>
                )}

                {/*TODO: update when we have audit data*/}
                {/*<Grid.Item>*/}
                {/*  <Grid.Container direction="column" spacing="none">*/}
                {/*    <Grid.Item>*/}
                {/*      <p className="hc-fs-xs">*/}
                {/*        Updated by FirstName.LastName, 00-00-0000, 00:00*/}
                {/*      </p>*/}
                {/*    </Grid.Item>*/}
                {/*  </Grid.Container>*/}
                {/*</Grid.Item>*/}
              </Grid.Container>
              <Grid.Container className="hc-pa-normal" direction="column">
                <Grid.Item>
                  {(stopEventError && (
                    <Heading className="hc-clr-error" size={5}>
                      Error getting stop events.
                    </Heading>
                  )) || <Heading size={5}>Stop Events</Heading>}
                </Grid.Item>
                {!stopEventError && (
                  <Grid.Item className="stop-events-table">
                    {(checkEdiEvents(stop.sequence_number) && (
                      <Table
                        name="stop-events-table"
                        aria-label={`Stop Events Table for Stop ${stop.sequence_number}`}
                        data-testid={`Stop Events Table for Stop ${stop.sequence_number}`}
                        data={{
                          columnDefs: stopEventsColumnDefs,
                          rowData: stopEventsRowData(stop.sequence_number),
                        }}
                      />
                    )) || (
                      <Heading
                        aria-label={`Stop Events for Stop ${stop.sequence_number}`}
                        size={6}
                      >
                        Stop Event data is not available for this stop.
                      </Heading>
                    )}
                  </Grid.Item>
                )}
              </Grid.Container>
            </ExpandableSection.Content>
          </ExpandableSection>
        </Card>
      </Grid.Item>
    )
  })
}
export default StopsInfoCard
