export const FILTER_LABELS = {
  load_ids: 'Load IDs',
  trip_ids: 'Trip IDs',
  route_ids: 'Route IDs',
  scacs: 'SCACs',
  pro_numbers: 'Pro #',
  trailer_numbers: 'Trailer #',
  purchase_order_numbers: 'Purchase Order #',
  schedule_ids: 'Schedule IDs',
  origin_ids: 'Origin',
  destination_ids: 'Destination',
  service_codes: 'Service Codes',
  load_statuses: 'Load Status',
  driver_status: 'Driver Status',
  alert_statuses: 'Alert Status',
  category_list: 'Load Category',
  hold_reason_code: 'Hold Reason Code',
  delivery_type: 'Unload Type',
  store_ids: 'Store IDs',
  critical_departure_start_time: 'Critical Departure Start Time',
  critical_departure_end_time: 'Critical Departure End Time',
  arrival_start_time: 'Arrival Start Time',
  arrival_end_time: 'Arrival End Time',
  start_from_date: 'Load Start From Date',
  start_to_date: 'Load Start To Date',
  load_create_from_date: 'Load Create From Date',
  load_create_to_date: 'Load Create To Date',
  required_actions: 'Actions Required',
}
