import { Button, Card, Grid, Heading } from '@enterprise-ui/canvas-ui-react'
import { useState } from 'react'
import MMDashboardDescription from './MMDashboardDescription'
import DashboardOverview from '../common/DashboardOverview'
import MMDashboardBody from './MMDashboardBody'

const ORIGIN_VIEW = 'ORIGIN'
const SCAC_VIEW = 'SCAC'

const MMDashboard = ({
  loadSummary,
  subheader,
  formatStateFilters = (_cell) => {
    return {}
  },
}) => {
  const [onView, setOnView] = useState(ORIGIN_VIEW)

  const xs = Math.floor(
    12 / (loadSummary?.dashboard_config.summary_columns + 1),
  )

  const toggleOnView = () => {
    if (onView === SCAC_VIEW) {
      setOnView(ORIGIN_VIEW)
    } else if (onView === ORIGIN_VIEW) {
      setOnView(SCAC_VIEW)
    }
    mapLoadSummary(loadSummary)
  }

  let mappedLoadSummary
  const mapLoadSummary = (loadSummary) => {
    const dashboardSummaries = loadSummary?.dashboard_summaries
    const uniqueValues = (key) =>
      [
        ...new Set(dashboardSummaries?.map((details) => details[key])?.sort()),
      ].filter((value) => value !== 'All')

    const scacs = uniqueValues('scac')
    const origins = uniqueValues('origin')

    const mapSummaries = (key, value) =>
      dashboardSummaries
        .filter((summary) => summary[key] === value)
        .flatMap((summary) => ({
          name: summary[key === 'scac' ? 'origin' : 'scac'],
          summary_cells: summary.summary_cells,
        }))

    mappedLoadSummary = (onView === SCAC_VIEW ? scacs : origins).map(
      (value) => ({
        name: value,
        summaries: mapSummaries(
          onView === SCAC_VIEW ? 'scac' : 'origin',
          value,
        ),
      }),
    )

    return mappedLoadSummary
  }

  return (
    <Card className="hc-pa-expanded">
      <Grid.Container direction="column">
        {/* MM Dashboard Header */}
        <Grid.Item>
          <Grid.Container justify="space-between">
            <Grid.Item>
              <Heading size={3}>
                Middle Mile General Merchandise Dashboard
              </Heading>
            </Grid.Item>
            <Grid.Item>
              <MMDashboardDescription />
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>

        {/* Search Filters Chips */}
        <Grid.Item>{subheader}</Grid.Item>

        {/* Dashboard Overview */}
        <DashboardOverview
          loadSummary={loadSummary}
          formatStateFilters={formatStateFilters}
        />

        {/* Scac/Origin View Toggle */}
        <Grid.Item>
          <Grid.Container justify="flex-end">
            <Grid.Item>
              <Button
                onClick={() => toggleOnView()}
                aria-label="Switch Table View Button"
              >
                Switch to {onView === SCAC_VIEW ? 'Origin' : 'SCAC'} View
              </Button>
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>

        {/* Dashboard Summaries Body */}
        {mapLoadSummary(loadSummary) && (
          <>
            <MMDashboardBody
              mappedLoadSummary={mappedLoadSummary}
              formatStateFilters={formatStateFilters}
              onView={onView}
              dashboardConfig={loadSummary?.dashboard_config}
              xs={xs}
            />
          </>
        )}
      </Grid.Container>
    </Card>
  )
}

export default MMDashboard
