import { Card, Grid, Heading, Table } from '@enterprise-ui/canvas-ui-react'
import TableDataCell from '../common/TableDataCell'

const buildTotalCells = (summary, displayRow, summaryLength) => {
  const firstSummary = summary?.summaries[0]

  return Array.from({ length: summaryLength }, (_, i) => {
    const displaySequence = i + 1

    return {
      display_name: firstSummary?.summary_cells?.find(
        (cell) =>
          cell.display_sequence === displaySequence &&
          cell.display_row === displayRow,
      )?.display_name,
      display_sequence: displaySequence,
      display_row: displayRow,
      load_count: getTotalCountForCell(
        summary?.summaries,
        displayRow,
        displaySequence,
      ),
      filters: getTotalFiltersForCell(
        summary?.summaries,
        displayRow,
        displaySequence,
      ),
    }
  })
}

const getTotalCountForCell = (summaries, displayRow, displaySequence) => {
  const summariesForCell = summaries.flatMap((summary) =>
    summary.summary_cells?.filter(
      (cell) =>
        cell.display_sequence === displaySequence &&
        cell.display_row === displayRow,
    ),
  )

  return summariesForCell.reduce((acc, summary) => {
    acc += summary.load_count
    return acc
  }, 0)
}

const getTotalFiltersForCell = (summaries, displayRow, displaySequence) => {
  const summariesForCell = summaries.flatMap((summary) =>
    summary.summary_cells?.filter(
      (cell) =>
        cell.display_sequence === displaySequence &&
        cell.display_row === displayRow,
    ),
  )

  const allFilters = summariesForCell.flatMap((summary) => summary.filters)

  const filterNames = [
    ...new Set(allFilters.map((filter) => filter?.filter_name)),
  ]
  return filterNames.map((filterName) => {
    return {
      filter_name: filterName,
      filter_values: [
        ...new Set(
          allFilters
            .filter((filter) => filter.filter_name === filterName)
            .flatMap((filter) => filter.filter_values),
        ),
      ],
    }
  })
}

const MMDashboardBody = ({
  mappedLoadSummary,
  formatStateFilters,
  onView,
  dashboardConfig,
  xs,
}) => {
  return (
    <Grid.Item>
      {/* Returns list of summary tables grouped by scac or origin */}
      {mappedLoadSummary.map((summary, index) => {
        return (
          <ScacOriginTable
            index={index}
            summary={summary}
            dashboardConfig={dashboardConfig}
            formatStateFilters={formatStateFilters}
            onView={onView}
            xs={xs}
          />
        )
      })}
    </Grid.Item>
  )
}

const ScacOriginTable = ({
  index,
  summary,
  dashboardConfig,
  formatStateFilters,
  onView,
  xs,
}) => {
  const summaryLength = dashboardConfig?.summary_columns
  const totalCells = buildTotalCells(summary, 1, summaryLength)
  const totalCellsLive = buildTotalCells(summary, 2, summaryLength)
  const totalCellsDrop = buildTotalCells(summary, 3, summaryLength)

  return (
    <Card
      className="hc-pa-expanded hc-mb-expanded"
      key={`${summary?.name}-${index}`}
    >
      <Heading size={4}>{summary?.name}</Heading>

      <Table name={`${summary?.name} Details`}>
        <ColumnHeaders
          xs={xs}
          onView={onView}
          cells={totalCells}
          summary={summary}
        />

        <Table.Body>
          {/* Total Rows */}
          <TableRow
            name={'Total'}
            cells={totalCells}
            formatStateFilters={formatStateFilters}
            xs={xs}
            rowNum={'1'}
            isTotalRow={true}
          />
          <TableRow
            name={'Live'}
            cells={totalCellsLive}
            rowNum={'2'}
            formatStateFilters={formatStateFilters}
            xs={xs}
          />
          <TableRow
            name={'Drop'}
            cells={totalCellsDrop}
            rowNum={'3'}
            formatStateFilters={formatStateFilters}
            xs={xs}
          />

          {/* SCAC/ORIGIN Summary Rows */}
          {summary?.summaries?.map((scacOriginSummary) => {
            const scacOriginCellMatrix =
              scacOriginSummary?.summary_cells?.reduce((acc, cell) => {
                if (!acc[cell.display_row]) {
                  acc[cell.display_row] = []
                }
                acc[cell.display_row].push(cell)
                return acc
              }, {})

            return Object.entries(scacOriginCellMatrix).map(
              ([rowNum, value]) => (
                <TableRow
                  name={scacOriginSummary?.name}
                  cells={value}
                  rowNum={rowNum}
                  formatStateFilters={formatStateFilters}
                  xs={xs}
                />
              ),
            )
          })}
        </Table.Body>
      </Table>
    </Card>
  )
}

const ColumnHeaders = ({ xs, onView, cells, summary }) => {
  return (
    <Table.Head>
      <Table.Row key={summary?.name}>
        <Table.Header xs={xs}>
          {onView === 'SCAC' ? 'Origin ID' : 'SCAC'}
        </Table.Header>
        <Table.Header xs={xs}>Unload Type</Table.Header>
        {cells.map((cell, index) => (
          <Table.Header xs={xs} key={`${cell.name}-${index}`}>
            {cell?.display_name}
          </Table.Header>
        ))}
      </Table.Row>
    </Table.Head>
  )
}

const TableRow = ({
  name,
  cells,
  rowNum,
  formatStateFilters,
  isTotalRow,
  xs,
}) => {
  const rowTotalCell = cells.find((cell) => cell.display_sequence === 1)
  return (
    <Table.Row key={`${name}-${rowNum}`}>
      <TableDataCell
        index={1}
        filters={formatStateFilters(rowTotalCell)}
        displayText={rowNum === '1' ? <strong>{name}</strong> : ''}
        xs={xs}
        isTotalRowCell={isTotalRow}
      />
      <TableDataCell
        index={2}
        filters={formatStateFilters(rowTotalCell)}
        displayText={rowNum === '2' ? 'Live' : rowNum === '3' ? 'Drop' : ''}
        xs={xs}
        isTotalRowCell={isTotalRow}
      />
      {cells
        .sort((a, b) => a.display_sequence - b.display_sequence)
        .map((cell, index) => (
          <TableDataCell
            key={index}
            index={index}
            filters={formatStateFilters(cell)}
            isDefectCell={index > 1}
            success={cell?.load_count === 0}
            displayText={
              isTotalRow ? (
                <strong>{cell?.load_count}</strong>
              ) : (
                cell?.load_count
              )
            }
            isTotalRowCell={isTotalRow}
            xs={xs}
          />
        ))}
    </Table.Row>
  )
}

export default MMDashboardBody
