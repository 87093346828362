import axios from 'axios'

import qsUtil from '../../../common/util/queryStringUtil'

import apiConfig from '../../../config/apiConfig'
import { format } from 'date-fns'
import { set } from 'lodash'
import { formatSearchFilterDate } from '../../../common/util/dateUtil'
import { ADMIN_LOAD_TRACKING_CRITERIA } from '../../../common/constants/criteriaConstants'

async function getLoads(params) {
  const mappedParams = {
    ...params,
    origin_ids: params.origin_ids
      ? params.origin_ids.map((value) => value.value || value.id)
      : [],
    destination_ids: params.destination_ids
      ? params.destination_ids.map((value) => value.value || value.id)
      : [],
    categories: params.category_list
      ? params.category_list.map((value) => value.type)
      : [],
    subcategories: params.category_list
      ? params.category_list.flatMap((value) => value.sub_types)
      : [],
    category_list: [],
    start_to_date: formatSearchFilterDate(params.start_to_date),
    start_from_date: formatSearchFilterDate(params.start_from_date),
    load_create_from_date: formatSearchFilterDate(params.load_create_from_date),
    load_create_to_date: formatSearchFilterDate(params.load_create_to_date),
  }
  const { data } = await axios.get(`${apiConfig.api.edge}/loads`, {
    params: qsUtil.createParams(mappedParams),
  })
  return data
}

async function getCriteria(criteriaId) {
  const { data } = await axios.get(
    `${apiConfig.api.edge}/criteria/${criteriaId}`,
  )
  return data
}

async function getEventTypes(user) {
  const { data } = await axios.get(`${apiConfig.api.edge}/event_types`)
  return data
}

const getRules = async () => {
  const { data } = await axios.get(
    `${apiConfig.api.edge}/rules/category/LOAD_TRACKING`,
  )
  return data
}

async function getLocation(user) {
  const { data } = await axios.get(`${apiConfig.api.edge}/dcs`)
  return data
}

async function getStopEvents(loadId) {
  const { data } = await axios.get(
    `${apiConfig.api.edge}/events/?load_id=${loadId}`,
  )
  return data
}

async function getMoves(loadId) {
  const { data } = await axios.get(
    `${apiConfig.api.edge}/latest/moves/${loadId}`,
  )
  return data
}

async function getCSVReport(loadSearchFilters, user, type) {
  const params = formatToCSVReportParams(loadSearchFilters, user, type)
  const { data } = await axios.get(`${apiConfig.api.edge}/loads/report`, {
    params: qsUtil.createParams(params),
  })
  return data
}

export function formatToCSVReportParams(loadSearchFilters, user, type) {
  return {
    isDescendingSort: loadSearchFilters.is_descending_sort,
    load_id_list: loadSearchFilters.load_ids,
    status_list: loadSearchFilters.load_statuses,
    route_ids: loadSearchFilters.route_ids,
    origin_ids: loadSearchFilters.origin_ids
      ? loadSearchFilters.origin_ids.map((value) => value.value || value.id)
      : [],
    destination_ids: loadSearchFilters.destination_ids
      ? loadSearchFilters.destination_ids.map(
          (value) => value.value || value.id,
        )
      : [],
    start_from_date: formatSearchFilterDate(loadSearchFilters.start_from_date),
    start_to_date: formatSearchFilterDate(loadSearchFilters.start_to_date),
    scac_search_list: loadSearchFilters.scacs,
    trip_id_list: loadSearchFilters.trip_ids,
    category_list: loadSearchFilters.category_list
      ? loadSearchFilters.category_list.map((value) => value.type)
      : [],
    subcategory_list: loadSearchFilters.category_list
      ? loadSearchFilters.category_list.flatMap((value) => value.sub_types)
      : [],
    pro_number_list: loadSearchFilters.pro_numbers,
    purchase_order_numbers: loadSearchFilters.purchase_order_numbers,
    required_actions: loadSearchFilters.required_actions,
    service_code_list: loadSearchFilters.service_codes,
    store_id_list: loadSearchFilters.store_ids,
    alert_status: loadSearchFilters.alert_statuses,
    critical_departure_start_time:
      loadSearchFilters.critical_departure_start_time,
    critical_departure_end_time: loadSearchFilters.critical_departure_end_time,
    arrival_start_time: loadSearchFilters.arrival_start_time,
    arrival_end_time: loadSearchFilters.arrival_end_time,
    trailer_number_list: loadSearchFilters.trailer_numbers,
    criteria_id: ADMIN_LOAD_TRACKING_CRITERIA,
    email: user.email,
    report_type: type,
    load_create_from_date: formatSearchFilterDate(
      loadSearchFilters.load_create_from_date,
    ),
    load_create_to_date: formatSearchFilterDate(
      loadSearchFilters.load_create_to_date,
    ),
    delivery_type: loadSearchFilters.delivery_type,
  }
}

// Logic copied from Mercury (with a bit of cleanup) --START

// Saves both load and stop level changes
function saveUpdates(recordUpdates, user) {
  const payload = formatLoadTrackingUpdatesDTO(recordUpdates, user)
  return axios.put(
    `${apiConfig.api.edge}/loads/update_tracking_details`,
    payload,
  )
}

export function formatLoadTrackingUpdatesDTO(recordUpdates, user) {
  return {
    event_details: formatLoadTrackingDetailsDTO(recordUpdates, user),
    event_type: 'CARRIER_EVENT',
    event_user: user.email,
  }
}

function formatLoadTrackingDetailsDTO(recordUpdates, user) {
  return Object.keys(recordUpdates).map((loadId) => {
    const updatedRecord = recordUpdates[loadId]
    const updatedRecordStops =
      updatedRecord && updatedRecord.stops ? updatedRecord.stops : {}
    const mergedDetails = {
      ...mapToCarrierTrackingDetails(updatedRecord, user),
    }
    const mergedStops = formatStops(updatedRecordStops, user)
    return {
      load_id: loadId,
      carrier_event: mergedDetails,
      stops: mergedStops,
    }
  })
}

export function formatStops(updatedStops, user) {
  const updatedStopDto = []

  Object.values(updatedStops).forEach((updated) => {
    updatedStopDto.push({
      stop_number: updated.stop_number,
      stop_type: updated.stop_type,
      carrier_event: {
        ...mapToCarrierTrackingDetails(updated, user),
      },
    })
  })

  return updatedStopDto
}

function mapToCarrierTrackingDetails(updatedRecord, user) {
  const dto = {
    pickup_details: null,
    delivery_details: null,
    pickup_or_delivery_details: null,
    defect_category: null,
    deadhead_reason_code: null,
    hold_reason_code: null,
    node_info: null,
    comments: null,
    tcc_comments: null,
    update_by: null,
    update_date: null,
    display_name: null,
  }

  const estimatedPickupTime = updatedRecord.estimated_pickup_time
  if (estimatedPickupTime || estimatedPickupTime === '') {
    set(dto, `pickup_details.estimated_date`, dateFormat(estimatedPickupTime))
  }

  const actualPickupTime = updatedRecord.actual_pickup_time
  if (actualPickupTime || actualPickupTime === '') {
    set(dto, `pickup_details.actual_date`, dateFormat(actualPickupTime))
  }

  const estimatedDeliveryTime = updatedRecord.estimated_delivery_time
  if (estimatedDeliveryTime || estimatedDeliveryTime === '') {
    set(
      dto,
      `delivery_details.estimated_date`,
      dateFormat(estimatedDeliveryTime),
    )
  }

  const actualDeliveryTime = updatedRecord.actual_delivery_time
  if (actualDeliveryTime || actualDeliveryTime === '') {
    set(dto, `delivery_details.actual_date`, dateFormat(actualDeliveryTime))
  }

  const estimatedPickOrDeliveryTime = updatedRecord.estimated_pick_or_drop_time
  if (estimatedPickOrDeliveryTime || estimatedPickOrDeliveryTime === '') {
    set(
      dto,
      `pickup_or_delivery_details.estimated_date`,
      dateFormat(estimatedPickOrDeliveryTime),
    )
  }

  const actualPickOrDeliveryTime = updatedRecord.actual_pick_or_drop_time
  if (actualPickOrDeliveryTime || actualPickOrDeliveryTime === '') {
    set(
      dto,
      `pickup_or_delivery_details.actual_date`,
      dateFormat(actualPickOrDeliveryTime),
    )
  }

  const pickupDefectReason = updatedRecord.pickup_defect_reason
  if (pickupDefectReason || pickupDefectReason === '') {
    set(dto, `pickup_details.defect_reason`, pickupDefectReason)
  }

  const deliveryDefectReason = updatedRecord.delivery_defect_reason
  if (deliveryDefectReason || deliveryDefectReason === '') {
    set(dto, `delivery_details.defect_reason`, deliveryDefectReason)
  }

  const carrierComments = updatedRecord.carrier_comments
  if (carrierComments || carrierComments === '') {
    dto.comments = carrierComments
  }

  const tccComments = updatedRecord.tcc_comments
  if (tccComments || tccComments === '') {
    dto.tcc_comments = tccComments
  }

  const defectReason = updatedRecord.defect_reason
  if (defectReason || defectReason === '') {
    set(dto, `pickup_or_delivery_details.defect_reason`, defectReason)
  }

  const defectCategory = updatedRecord.defect_category
  if (defectCategory || defectCategory === '') {
    dto.defect_category = defectCategory
  }

  const deadheadReasonCode = updatedRecord.deadhead_reason_code
  if (deadheadReasonCode || deadheadReasonCode === '') {
    dto.deadhead_reason_code = deadheadReasonCode
  }

  const holdReasonCode = updatedRecord.hold_reason_code
  if (holdReasonCode || holdReasonCode === '') {
    dto.hold_reason_code = holdReasonCode
  }

  dto.node_info = updatedRecord.node_info
  dto.display_name = updatedRecord.display_name
  dto.update_by = user.email
  dto.update_date = new Date()

  return dto
}

// Logic copied from Mercury (with a bit of cleanup) --END

export function dateFormat(date) {
  if (date === '') {
    return '0001-01-01T01:01:01.001'
  }

  return format(new Date(date), "yyyy-MM-dd'T'HH:mm:ss.SSS")
}

export default {
  getLoads,
  getCriteria,
  getStopEvents,
  getMoves,
  saveUpdates,
  getEventTypes,
  getLocation,
  getCSVReport,
  getRules,
}
