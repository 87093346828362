import { isArray, upperCase } from 'lodash'

import { NovaFilterDrawer } from '@dlm/common'

import { DEFAULT_LOAD_SEARCH_FILTERS } from '../constants/loadSearchFilterConstants'
import { originDestinationFilterValidationRule } from '../util/validationUtil'

const mapLoadCategoryOptions = (categories) => {
  const mappedCategories = []

  categories.forEach((category) => {
    const mappedCategory = {
      label: upperCase(category.type),
      values: [],
    }

    category.sub_types.forEach((subCategory) => {
      mappedCategory.values.push({
        value: subCategory,
        label: upperCase(subCategory),
      })
    })

    mappedCategories.push(mappedCategory)
  })

  return mappedCategories
}

const LoadTrackingSearchFilterDrawer = ({
  onChange,
  isVisible,
  onRequestClose,
  loadStatusOptions,
  alertStatusOptions,
  loadCategories,
  holdReasonCodeOptions,
  nodeInfo,
  requiredActionsOptions,
  onSaveSearch,
  givenValues,
  savedSearches,
}) => {
  const categoryOptions = mapLoadCategoryOptions(loadCategories)
  const driverStatusOptions = [
    // TODO: Future update. This needs to come from criteria.
    { label: 'DRIVER STARTED', value: 'DRIVER_STARTED' },
    { label: 'IN TRANSIT', value: 'IN_TRANSIT' },
    { label: 'COMPLETED', value: 'COMPLETED' },
  ]
  const deliveryTypeOptions = [
    { label: 'LIVE', value: 'LIVE' },
    { label: 'DROP', value: 'DROP' },
  ]

  const handleChange = (values) => {
    // Needed to strip out object metadata added by hook form for tests to pass
    onChange(Object.assign({}, values))
    onRequestClose()
  }

  function handleSaveSearch(values) {
    onSaveSearch(values)
  }

  const filterMeta = {
    1: [
      {
        type: 'multi-text',
        name: 'load_ids',
        label: 'Load IDs',
        ariaLabel: 'Load ID List',
        placeholder: 'Comma separated list of Load IDs',
        xs: 12,
      },
    ],
    2: [
      {
        type: 'multi-text',
        name: 'trip_ids',
        label: 'Trip IDs',
        ariaLabel: 'Trip ID List',
        placeholder: 'Comma separated list of Trip IDs',
        xs: 12,
      },
    ],
    3: [
      {
        type: 'multi-text',
        name: 'route_ids',
        label: 'Route IDs',
        ariaLabel: 'Route ID List',
        placeholder: 'Comma separated list of Route IDs',
        xs: 12,
      },
    ],
    4: [
      {
        type: 'multi-text',
        name: 'pro_numbers',
        label: 'Pro Numbers',
        ariaLabel: 'Pro Number List',
        placeholder: 'Comma separated list of Pro Numbers',
        xs: 6,
      },
      {
        type: 'multi-text',
        name: 'trailer_numbers',
        label: 'Trailer Numbers',
        ariaLabel: 'Trailer Number List',
        placeholder: 'Comma separated list of Trailer Numbers',
        xs: 6,
      },
    ],
    5: [
      {
        type: 'multi-text',
        name: 'purchase_order_numbers',
        label: 'Purchase Order Numbers',
        ariaLabel: 'Purchase Order Number List',
        placeholder: 'Comma separated list of Purchase Order Numbers',
        xs: 6,
      },
      {
        type: 'multi-text',
        name: 'schedule_ids',
        label: 'Schedule IDs',
        ariaLabel: 'Schedule ID List',
        placeholder: 'Comma separated list of Schedule IDs',
        xs: 6,
      },
    ],
    6: [
      {
        type: 'multi-text',
        name: 'scacs',
        label: 'SCACs',
        ariaLabel: 'SCAC List',
        placeholder: 'Comma separated list of SCACs',
        xs: 6,
      },
      {
        type: 'multi-text',
        name: 'service_codes',
        label: 'Service Codes',
        ariaLabel: 'Service Code List',
        placeholder: 'Comma separated list of Service Codes',
        xs: 6,
      },
    ],
    7: [
      {
        type: 'autocomplete',
        name: 'origin_ids',
        label: 'Origin',
        multiselect: true,
        rules: originDestinationFilterValidationRule(),
        allowCustomInputValue: true,
        ariaLabel: 'Origin Location ID List',
        placeholder: 'Comma separated list of Origin IDs',
        options: nodeInfo,
        xs: 6,
        autocompleteType: 'grouped',
      },
      {
        type: 'autocomplete',
        name: 'destination_ids',
        multiselect: true,
        rules: originDestinationFilterValidationRule(),
        allowCustomInputValue: true,
        label: 'Destination',
        ariaLabel: 'Destination Location ID List',
        placeholder: 'Comma separated list of Destination IDs',
        options: nodeInfo,
        xs: 6,
        autocompleteType: 'grouped',
      },
    ],
    8: [
      {
        type: 'datetime-local',
        name: 'critical_departure_start_time',
        label: 'Critical Departure Start Time',
        xs: 6,
      },
      {
        type: 'datetime-local',
        name: 'critical_departure_end_time',
        label: 'Critical Departure End Time',
        xs: 6,
      },
    ],
    9: [
      {
        type: 'datetime-local',
        name: 'arrival_start_time',
        label: 'Arrival Start Time',
        xs: 6,
      },
      {
        type: 'datetime-local',
        name: 'arrival_end_time',
        label: 'Arrival End Time',
        xs: 6,
      },
    ],
    10: [
      {
        type: 'date',
        name: 'load_create_from_date',
        label: 'Load Create From Date',
        xs: 6,
      },
      {
        type: 'date',
        name: 'load_create_to_date',
        label: 'Load Create To Date',
        xs: 6,
      },
    ],
    11: [
      {
        type: 'date',
        name: 'start_from_date',
        label: 'Load Start From Date',
        xs: 6,
      },
      {
        type: 'date',
        name: 'start_to_date',
        label: 'Load Start To Date',
        xs: 6,
      },
    ],
    12: [
      {
        type: 'checkboxes',
        name: 'load_statuses',
        label: 'Load Status',
        options: loadStatusOptions,
        xs: 6,
      },
      {
        type: 'checkboxes',
        name: 'alert_statuses',
        label: 'Alert Status',
        options: alertStatusOptions,
        xs: 6,
      },
    ],
    13: [
      {
        type: 'checkbox-groups',
        name: 'category_list',
        label: 'Load Category',
        options: categoryOptions,
        xs: 12,
        direction: 'row',
      },
    ],
    14: [
      {
        type: 'checkboxes',
        name: 'required_actions',
        label: 'Actions Required',
        options: requiredActionsOptions,
        xs: 5,
      },
      {
        type: 'checkboxes',
        name: 'driver_status',
        label: 'Driver Status',
        options: driverStatusOptions,
        xs: 3,
      },
      {
        type: 'checkboxes',
        name: 'hold_reason_code',
        label: 'Hold Reason Code',
        options: holdReasonCodeOptions,
        xs: 4,
      },
      {
        type: 'checkboxes',
        name: 'delivery_type',
        label: 'Unload Type',
        options: deliveryTypeOptions,
        xs: 4,
      },
    ],
  }

  const existingSearches = isArray(savedSearches) ? savedSearches : []

  const filterActionsMeta = {
    1: [
      {
        type: 'reset',
        xs: 6,
      },
      {
        type: 'save-search',
        xs: 6,
        action: handleSaveSearch,
        currentSavedSearches: existingSearches,
      },
    ],
    2: [
      {
        type: 'submit',
        xs: 12,
      },
    ],
  }

  return (
    <NovaFilterDrawer
      isVisible={isVisible}
      onRequestClose={onRequestClose}
      headingText="Load Search Filters"
      location="right"
      filterMeta={filterMeta}
      filterActionsMeta={filterActionsMeta}
      defaultValues={DEFAULT_LOAD_SEARCH_FILTERS}
      values={givenValues}
      onSubmit={handleChange}
    />
  )
}

export default LoadTrackingSearchFilterDrawer
