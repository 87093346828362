import { formatInTimeZone } from 'date-fns-tz'

const date_options = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour12: true,
  hour: 'numeric',
  minute: 'numeric',
}

function getTimeZoneOffset() {
  let offset = new Date().getTimezoneOffset()
  const sign = offset > 0 ? '-' : '+'
  offset = Math.abs(offset)
  const hours = Math.floor(offset / 60)
  const minutes = offset % 60
  return (
    sign +
    (hours < 10 ? '0' : '') +
    hours +
    ':' +
    (minutes < 10 ? '0' : '') +
    minutes
  )
}

export const isValidDate = (date) => {
  return date instanceof Date && !isNaN(date)
}

function getDefaultTimeZoneId() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

export const formatDate = (dateString) => {
  const date = new Date(dateString)
  if (isValidDate(date)) {
    return date.toLocaleString('en-US', date_options).split('T')[0]
  }
  return ''
}

export const formatDateTime = (dateString, timeZoneId) => {
  const defaultTimeZoneId = getDefaultTimeZoneId()
  return isValidDate(new Date(dateString))
    ? formatInTimeZone(
        dateString,
        timeZoneId || defaultTimeZoneId,
        'M/d/yyyy h:mm a zzz',
      )
    : ''
}

export const formatDateTimeForInput = (dateString, timeZoneOffsetFromUTC) => {
  const defaultTimezoneOffset = getTimeZoneOffset()
  return isValidDate(new Date(dateString))
    ? formatInTimeZone(
        dateString,
        timeZoneOffsetFromUTC || defaultTimezoneOffset,
        "yyyy-MM-dd'T'HH:mm",
      )
    : ''
}

export const formatSearchFilterDate = (dateString) => {
  const date = new Date(dateString)
  if (isValidDate(date)) {
    return date.toISOString().split('T')[0]
  }
  return ''
}

export const convertToLocationZone = (dateString, timeZoneId) => {
  return formatDateTime(dateString, timeZoneId)
}
