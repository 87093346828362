import { Table } from '@enterprise-ui/canvas-ui-react'
import { NavLink } from 'react-router-dom'

const TableDataCell = ({
  index,
  filters,
  isDefectCell,
  isTotalRowCell,
  success,
  displayText,
  xs,
}) => {
  return (
    <Table.Data xs={xs} key={index}>
      {/* DEBUG: Tooltip for filters */}
      {/* <Tooltip content={JSON.stringify(filters, null, 2)} location="bottom">*/}
      <NavLink
        to=".."
        state={{
          filters: filters,
        }}
      >
        <div
          style={isTotalRowCell ? { fontSize: '18px' } : {}}
          className={`${
            isDefectCell ? (success ? 'hc-clr-success' : 'hc-clr-error') : ''
          }`}
        >
          {displayText}
        </div>
      </NavLink>
      {/*</Tooltip>*/}
    </Table.Data>
  )
}

export default TableDataCell
