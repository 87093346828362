import { ActionArea, Card, Grid, Heading } from '@enterprise-ui/canvas-ui-react'
import { NavLink } from 'react-router-dom'

const ActionCard = ({
  className,
  filters,
  title,
  count,
  statusColor = true,
}) => {
  const statusClass = statusColor
    ? count === 0
      ? 'hc-clr-success'
      : 'hc-clr-error'
    : ''

  return (
    <Grid.Item className={className} xs={12} md={6} xl={3}>
      <ActionArea
        as={NavLink}
        to=".."
        state={{
          filters: filters,
        }}
        data-testid={`${title}-action-area`}
      >
        <Card className="dashboard-card">
          <Grid.Container direction="column" className="hc-pl-md hc-pt-md">
            <Grid.Item className="hc-pv-none">
              <div className="hc-fs-xs">{title}</div>
            </Grid.Item>
            <Grid.Item className="hc-pt-none">
              <div className={`${statusClass} hc-fs-3x`}>
                <strong>{count || 0}</strong>
              </div>
            </Grid.Item>
          </Grid.Container>
        </Card>
      </ActionArea>
    </Grid.Item>
  )
}

const DashboardOverview = ({ loadSummary, formatStateFilters }) => {
  let overview
  const getAllSummary = (loadSummary) => {
    const allSummary = loadSummary?.dashboard_summaries?.find(
      (summary) => summary.scac === 'All',
    )
    const length = allSummary?.summary_cells?.length ?? 0

    overview = Array.from({ length: length }, (_, i) => {
      const displaySequence = i + 1
      return allSummary?.summary_cells?.find(
        (cell) => cell.display_sequence === displaySequence,
      )
    })

    return overview
  }

  const itemClassMap = {
    0: 'first-item',
    1: 'second-item',
    2: 'third-item',
    3: 'fourth-item',
    4: 'fifth-item',
    5: 'sixth-item',
    6: 'seventh-item',
    7: 'eighth-item',
  }

  return (
    <>
      <Grid.Item>
        <Grid.Container>
          <Grid.Item>
            <Heading size={4}>
              <strong>Overview</strong>
            </Heading>
          </Grid.Item>
        </Grid.Container>
      </Grid.Item>
      {getAllSummary(loadSummary) && (
        <Grid.Item className="mm-overview">
          <Grid.Container direction="row" align="center">
            {overview.map((cell, index) => (
              <ActionCard
                key={index}
                className={itemClassMap[index]}
                title={cell?.display_name}
                count={cell?.load_count}
                filters={formatStateFilters(cell)}
                statusColor={index > 1}
              />
            ))}
          </Grid.Container>
        </Grid.Item>
      )}
    </>
  )
}

export default DashboardOverview
